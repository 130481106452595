
@import "~@packages/theme";


.text {

  display: block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -.24px;
  line-height: 1.4;
  color: $black;
  overflow: hidden;
  text-overflow: ellipsis;
}


@import "~@packages/theme";


.wrapper {

  display: block;
}

.content {

  display: flex;
  flex-direction: row;
  flex: 1 0;
  align-items: center;
}
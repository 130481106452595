
@import "~@packages/theme";


.list {

  display: block;
  list-style: disc;

  li {
    padding-left: 10px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      width: 4px;
      height: 4px;
      background-color: #000;
      border-radius: 50%;
    }
  }

  & + p {
    margin-top: 12px;
  }
}


@import "~@packages/theme";


.wrapper {

  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0;
}

.logotype {

  position: absolute;
  top: 35%;
  display: inline-block;
  width: 100%;
  text-align: center;
}

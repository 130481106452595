
@import "./_base/reset.css";

@import "./fonts/stem.css";


:global {

  html,
  body {

    display: block;
    width: 100%;
    height: 100%;
  }

  body {

    font-family: "Stem", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {

    display: flex;
    flex: 1 0;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    z-index: 10;
  }

  b {

    font-weight: 700;
  }
}


@import "~@packages/theme";


.wrapper {

  position: relative;
  right: -24px;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 16px;
  height: 320px;
  justify-content: center;
  align-items: center;
  margin: 0 -16px 0 0;
  cursor: pointer;

  @media (max-width: $mobile) {
    display: none;
  }
}

.icon {

  display: block;
  width: 16px;
  height: 28px;
  background: url("./arrow.svg") no-repeat;
}


@import "~@packages/theme";


.status {

  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: $blue-grey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.success {

  color: $success;
}

.danger {

  color: $danger;
}

.primary {

  color: $primary;
}

.warning {

  color: $warning;
}

@import "~@packages/theme";


.wrapper {

  display: flex;
  flex: 1 0;
  flex-direction: column;
}

.label {

  display: block;
  font-size: 12px;
  padding-left: 11px;
  margin-bottom: 2px;
  color: $gray3;

  &--require {
    &:after {

      display: inline-block;
      content: '*';
      color: $danger;
      margin: 0 0 0 2px;
    }
  }
}

.container {

  position: relative;
  display: block;
}

.error {

  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  font: 300 .6em 'Stem';
  color: #ee5a07;

  &__message {

    font-family: inherit;
    font-weight: 500;
    font-size: 10px;
    color: $danger;
    margin: 0 0 0 11px;
    white-space: nowrap;
  }
}

.mode {
  &--info {
    .label{
      color: $information;
    }
  }

  &--primary {
    .label{
      color: $primary;
    }
  }

  &--success {

    .label {
      color: $success;
    }
  }

  &--warning {
    .label {
      color: $warning;
    }
  }

  &--danger {
    .label {
      color: $danger;
    }
  }

}

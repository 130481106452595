
@import "~@packages/theme";


.line {

  display: block;
  position: relative;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.46;
  color: $black;
  padding-left: 20px;

  @media (max-width: $mobile) {
    font-size: 15px;
  }

  &:before {

    display: inline-block;
    position: absolute;
    left: 0;
    content: counters(item, ".") ".";
    counter-increment: item;
    font: inherit;
  }

  & + * {

    margin: 12px 0 0;
  }

  ul {

    list-style: disc;

    .line {

      padding-left: 32px;

      ul {

        list-style: disc;

        .line {

          padding-left: 42px;
        }
      }
    }
  }
}


// colors
$white: #fff;
$black: #000;
$blue: #077dee;
$blue2: #18e0ed;
$dark: #04102a;
$gray: #c2cad7;
$gray2: #e2e2ea;
$gray3: #92929d;
$gray4: #fdfefe;
$gray5: #f5f8fd;
$gray6: #7c7c7c;
$gray7: #dfe3e9;
$gray8: #f5f5f5;
$gray9: #c0c0c0;
$gray10: #b5b5be;
$gray11: #65657c;
$gray12: #e3e3ef;
$gray13: #f0f0f5;
$green: #1bb934;
$green2: #34c71e;
$green3: #aed04c;
$danger: #ee5a07;
$yellow: #ffc542;
$bright-red: #f04661;

// font weight
$f-regular: 300;
$f-upper: 400;
$f-medium: 500;
$f-bold: 700;

// font sizes
$font-default: 20px;

// transitions
$all-transition: all .2s ease-in-out;

// screen sizes
$micro: 540px;
//$mobile: 767px;
$mobile: 1023px;
$tablet: 991px;
$screen: 1149px;
$desctop: 1439px;
$large: 1660px;





$danger:           #b9311b !default;
$warning:          #da6926 !default;
$success:          #1bb934 !default;
$primary:          #077dee !default;
$information:      #ffc542 !default;

$black:            #000000 !default;
$coal-dark:        #171725 !default;
$dark-grey:        #354052 !default;
$charcoal-grey:    #44444f !default;
$blue-grey:        #92929d !default;
$gray:             #c2c2c2 !default;
$pale-lilac:       #e2e2ea !default;
$pale-grey:        #fafafb !default;
$very-light-pink:  #f7f7f7 !default;
$very-light-blue:  #3c3c43 !default;
$light-gray-blue:  #F6F8FC !default;
$dark-grey-blue:   #354052 !default;
$whitesmoke:       #f6f6f4 !default;
$white:            #ffffff !default;

$rich-blue-44:       rgba(0, 41, 255, .44) !default;
$rich-blue-12:       rgba(0, 41, 255, .12) !default;


@import "~@packages/theme";


.text {

  display: block;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $black;
  overflow: hidden;
  text-overflow: ellipsis;
}


@import "~@packages/theme";


.wrapper {

  display: inline-block;
}

.link {

  display: flex;
  flex-direction: row;
  flex: 1 0;
  align-items: center;
  text-decoration: none;
  max-width: 200px;
  width: 100%;

  img {
    max-width: 100%;
  }
}

.slogan {

  display: block;
  margin-left: 32px;
  font-size: 10px;
  line-height: 1.36;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $black;
}

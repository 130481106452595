
@import "~@packages/theme";


.wrapper {

  position: fixed;
  top: 0;
  left: 0;
  display: block;
  padding: 8px 0 0;
  z-index: 99999;
}

.options {

  display: block;
  background-color: $white;
  border-radius: 32px;
  padding: 8px 8px 14px;
  box-shadow: 0 4px 10px 0 $pale-lilac;
}

.empty {

  display: block;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: inherit;
  font-weight: 300;
  font-size: 14px;
  color: $black;
  text-align: center;
}

.content {

  display: block;
}

.controls {

  display: block;
  margin: 10px 0 0;
  padding: 14px 0 0;
  text-align: right;
  border-top: 1px solid $pale-lilac;
}

.reset {

  display: inline-block;
  font-size: 16px;
  color: $danger;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.apply {

  display: inline-block;
  font-size: 16px;
  color: $success;
  cursor: pointer;
}


@import "~@packages/theme";


.wrapper {

  display: block;
  height: 214px;
  width: 200px;
  border-radius: 4px;
  background-color: $gray8;
  border: 1px solid $gray2;
}

.content {

  display: flex;
  flex-direction: column;
  flex: 1 0;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.label {

  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 1.6;
}

.link {

  display: inline-block;
  margin: 4px 0 0;
  font-size: 14px;
  color: $primary;
  cursor: pointer;
  text-decoration: underline;
}


@import "~@packages/theme";


.wrapper {

  display: block;
  width: 330px;
}

.header {

  display: block;
}

.content {

  display: block;
  margin: 34px 0 0;
}

.controls {

  display: block;
  margin: 30px 0 0;
}

.field {

  display: block;

  & + & {

    margin: 20px 0 0;
  }
}

.section {

  display: flex;
  flex-direction: column;
  flex: 1 0;

  & + & {

    margin: 16px 0 0;
  }
}


@import "~@packages/theme";


.notifications {

  position: fixed;
  bottom: 40px;
  right: 0;
  display: flex;
  flex: 0 0;
  flex-direction: column;
  min-width: 400px;
  padding: 0 10px;
  z-index: 900000;
  box-sizing: border-box;

  &__content {

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
}

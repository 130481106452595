
@import "~@packages/theme";


.container {

  position: relative;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  background: $light-gray-blue;
  border-radius: 10px;
  overflow: hidden;
  transition: border 200ms;

  &--focus {

    border-color: $coal-dark;
  }
}

.icon {

  position: absolute;
  top: 50%;
  left: 8px;
  display: inline-block;
  font-size: 16px;
  color: $blue-grey;
  margin-top: -8px;
}

.input {

  display: block;
  padding: 8px 7px 8px 30px;
  color: $blue-grey;
  font-family: inherit;
  font-weight: 500;
  font-size: 17px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  border: none;
  background: transparent;

  &:disabled {

    background: $pale-grey;
    color: $gray;
  }
}

.disabled,
.disabled:hover {

  border-color: $pale-lilac;
}

.placeholder {

  position: absolute;
  top: 50%;
  left: 30px;
  font-size: 17px;
  margin-top: -8px;
  color: $blue-grey;
}

.mode {
  &--info,
  &--info:hover {

    border-color: $information;
  }

  &--primary,
  &--primary:hover {

    border-color: $primary;
  }

  &--success,
  &--success:hover {

    border-color: $success;
  }

  &--warning,
  &--warning:hover {

    border-color: $warning;
  }

  &--danger,
  &--danger:hover {

    border-color: $danger;
  }

  &--disabled {

    background: $pale-grey;
    border-color: $pale-lilac;
    color: $gray;
  }
}


@import "~@packages/theme";


.link {

  display: block;
  font: 500 12px/1.46 'Stem';
  letter-spacing: 0;
  color: $black;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;

  //&:hover {
  //  color: $blue;
  //}

  & + * {
    &:before {

      display: inline-block;
      content: '/';
      margin: 0 3px 0;
      padding: 0 3px 0;
    }
  }
}

.title {

  display: block;
  font: 500 12px/1.46 'Stem';
  letter-spacing: 0;
  color: $bright-red;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 350px;
  overflow: hidden;
}


@import "~@packages/theme";


.header {

  display: block;
  font-family: inherit;
  font-weight: 300;
  font-size: 18px;
  color: $black;
  line-height: 1.4;
  text-overflow: ellipsis;
  overflow: hidden;

  & + * {

    margin: 20px 0 0;
  }
}

.bold {

  font-weight: 700;
}


@import "~@packages/theme";


.wrapper {

  display: block;
}

.label {

  display: block;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: $blue-grey;
  text-transform: uppercase;
}

.content {

  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  color: $black;
  margin: 4px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

@import "~@packages/theme";


.list {

  display: block;
  counter-reset: item;

  & & {

    margin: 12px 0 0 1em;
  }

  & + p {
    margin-top: 12px;
  }
}

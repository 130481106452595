
@import "~@packages/theme";

.button {

  display: inline-block;
  outline: 0 none;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid $bright-red;
  color: $bright-red;
  padding: 11px 30px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: normal;
  font-family: inherit;
  cursor: pointer;
  transition: opacity .15s ease-in-out;

  &:hover {

    opacity: .9;
  }

  &.disabled {

    cursor: default;
    background-color: transparent;
    border-color: $gray;

    &:hover {
      opacity: 1;
    }
  }
}

.mode--primary {


}

.mode--success {

  background-color: transparent;
  border-color: $green;
  color: $green;
}

.size--small {

}

.size--large {

  font-size: 16em;
}

.size--giant {

}

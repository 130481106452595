
@import "~@packages/theme";


.text {

  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  color: $black;

  @media (max-width: $mobile) {
    font-size: 15px;
    line-height: 1.467;
  }

  & + .text {
    margin-top: 1em;
  }

  & + ul {

    margin: 1em 0 0;
  }

  & + ol {

    margin: 1em 0 0;
  }

  & + h4 {

    margin: 20px 0 0;
  }
}

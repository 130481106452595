
@import "~@packages/theme";


.wrapper {

  display: block;
}

.container {

  display: flex;
  flex: 1 0;
  flex-direction: column;
  border: 1px solid $pale-lilac;
  border-radius: 32px;
  background: $white;
  overflow: hidden;
  transition: border 200ms;

  &:hover,
  &--focus {

    border-color: $coal-dark;
  }

  &--info,
  &--info:hover {

    border-color: $information;
  }

  &--primary,
  &--primary:hover {

    border-color: $primary;
  }

  &--success,
  &--success:hover {

    border-color: $success;
  }

  &--warning,
  &--warning:hover {

    border-color: $warning;
  }

  &--danger,
  &--danger:hover {

    border-color: $danger;
  }

  &--disabled {

    background: $pale-grey;
    border-color: $pale-lilac;
    color: $gray;
  }
}

.input {

  display: block;
  padding: 10px 15px;
  color: $black;
  font-family: inherit;
  font-weight: 300;
  font-size: 14px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  border: none;
  letter-spacing: .5px;
  background: transparent;

  &:disabled {

    border-color: $pale-lilac;
    color: $gray;
  }
}

.label {

  display: block;
  font-size: 12px;
  padding-left: 11px;
  margin-bottom: 2px;
  color: $gray3;
}

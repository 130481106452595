
@import "~@packages/theme";


.wrapper {

  display: block;
  width: 275px;
  margin: 30px auto;
  text-align: center;
}

.icon {

  display: inline-block;
  width: 32px;
  height: 23px;
  margin: 0 0 37px;
}

.title {

  display: block;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.description {

  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #354052;
  margin: 28px 0 0;
}

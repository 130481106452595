
@import "~@packages/theme";


.header {

  display: block;
  font-family: inherit;
  font-weight: 700;
  font-size: 20px;
  color: $black;
  line-height: 1.4;
  letter-spacing: -.5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bold {

  font-weight: 700;
}

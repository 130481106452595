
@import "~@packages/theme";


.container {

  position: relative;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  overflow: hidden;
  transition: border 200ms;
}

.input {

  display: block;
  height: 40px;
  padding: 0 20px;
  color: $black;
  border: 1px solid $gray2;
  border-radius: 32px;
  background-color: $white;
  font-family: "Stem", sans-serif;
  font-size: 14px;
  outline: 0 none;
  box-sizing: border-box;

  &:disabled {

    background: $pale-grey;
    color: $gray;
  }
}

.disabled,
.disabled:hover {

  border-color: $pale-lilac;
}

.label {

  display: block;
  font-size: 12px;
  padding-left: 11px;
  margin-bottom: 2px;
  color: $gray3;
}

.mode {
  &--info,
  &--info:hover {

    border-color: $information;
  }

  &--primary,
  &--primary:hover {

    border-color: $primary;
  }

  &--success,
  &--success:hover {

    border-color: $success;
  }

  &--warning,
  &--warning:hover {

    border-color: $warning;
  }

  &--danger,
  &--danger:hover {

    border-color: $danger;
  }

  &--disabled {

    background: $pale-grey;
    border-color: $pale-lilac;
    color: $gray;
  }
}

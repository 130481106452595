
@import "~@packages/theme";


.wrapper {

  position: -webkit-sticky;
  position: sticky;
  top: 24px;
  display: block;
}

.section {

  display: block;

  & + & {

    margin: 40px 0 0;

    @media(max-width: $mobile) {
      margin: 20px 0 0;
    }
  }
}

.item {

  display: block;

  & + & {

    margin: 12px 0 0;
  }
}

.link {

  display: inline-block;
  color: $black;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.46;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 200ms;

  &:hover {

    color: $bright-red;
  }
}

.title {

  display: block;
  color: inherit;
}


:global {

  .active {

    color: $bright-red;
  }
}

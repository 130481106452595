
@import "~@packages/theme";


.wrapper {

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex: 0 0;
  flex-direction: column;
  padding: 40px 0;
  z-index: 9999;
  overflow: auto;
  background-color: transparentize($black, .8);
}

.dialog {

  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 64px 56px 56px;
  background: $white;
  min-width: 240px;
  min-height: 100px;
  border-radius: 16px;
  margin: auto;
  box-sizing: border-box;
  box-shadow: 0 3px 10px 2px rgba($dark-grey, 0.3);
  overflow: hidden;

  @media (max-width: $mobile) {
    max-width: 90%;
    width: 100%;
    padding: 32px 24px 28px;
  }
}

.close {

  position: absolute;
  top: 40px;
  right: 40px;
  width: 12px;
  height: 12px;
  font-size: 10px;
  cursor: pointer;
  transition: transform .15s ease-in-out;

  @media (max-width: $mobile) {
    top: 16px;
    right: 16px;
  }
}

.content {

  display: flex;
  flex-direction: column;
  flex: 1 0;

}


@import "~@packages/theme";


.header {

  display: block;
  font-size: 56px;
  color: #04102a;
  font-weight: 400;

  @media (max-width: $mobile) {
    font-size: 28px;
  }
}

.bold {

  font-weight: 700;
}


@import "../../../../../../node_modules/@packages/theme";


.header {

  display: block;
  font-family: inherit;
  font-weight: 500;
  font-size: 18px;
  color: $coal-dark;
  line-height: 1.4;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 1em 0 20px;
}

.bold {

  font-weight: 700;
}

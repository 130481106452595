
@import "~@packages/theme";


.header {

  display: block;
  font-size: 32px;
  color: #04102a;
  letter-spacing: -.5px;
  line-height: 1.188;
  font-weight: 500;

  @media (max-width: $mobile) {
    font-size: 28px;
    line-height: 1.214;
  }
}

.bold {

  font-weight: 700;
}


@import "~@packages/theme";


.select {

  display: flex;
  flex: 1 0;
  border: 1px solid $pale-lilac;
  border-radius: 32px;
  cursor: pointer;
  background-color: $white;
  transition: border-color 200ms, background-color 200ms;

  &--hover {
    border-color: $coal-dark;
  }

  &--disabled {
    background-color: $pale-grey;
  }
}

.container {

  display: flex;
  flex: 1 0;
  flex-direction: column;
  overflow: auto;
}

.content {

  display: flex;
  flex: 1 0;
  flex-direction: column;
  overflow: hidden;
}

.values {

  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.text,
.placeholder {

  display: block;
  font-family: inherit;
  font-weight: 300;
  font-size: 14px;
  padding: 13px 0 12px 16px;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  transition: color 200ms;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &--disabled {
    color: $blue-grey;
  }
}

.placeholder {

  color: $blue-grey;
  font-style: italic;
}

.controls {

  display: flex;
  flex-direction: row;
  flex: 0 0;
  margin: 0 7px 0 0;
}

.mode {
  &--info,
  &--info:hover {

    border-color: $information;
  }

  &--primary,
  &--primary:hover {

    border-color: $primary;
  }

  &--success,
  &--success:hover {

    border-color: $success;
  }

  &--warning,
  &--warning:hover {

    border-color: $warning;
  }

  &--danger,
  &--danger:hover {

    border-color: $danger;
  }

  &--disabled {

    background: $pale-grey;
    border-color: $pale-lilac;
    color: $gray;
  }
}



@import "~@packages/theme";


.wrapper {

  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  height: 170px;
  background: $white;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}


@import "~@packages/theme";


.wrapper {

  display: flex;
  flex: 1 0;
  flex-direction: column;
}

.container {

  position: relative;
  display: block;
}

.error {

  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  font-size: 0;
  color: #ee5a07;

  &__message {

    display: block;
    font-family: inherit;
    font-weight: 500;
    font-size: 10px;
    color: $danger;
    margin: 0 0 0 11px;
    white-space: nowrap;
  }
}


@import "~@packages/theme";


.footer {

  padding: 73px 0 55px;
  background-color: $white;

  @media (max-width: $mobile) {
    padding: 56px 0 44px;
    background-color: transparent;
  }
}

.container {

  display: flex;
  flex-direction: column;
  flex: 1 0;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 64px;
  box-sizing: border-box;

  @media (max-width: $mobile) {

    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px;
    width: 100%;
  }
}

.section {

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0;

  @media (max-width: $mobile) {
    flex-direction: column-reverse;
  }
}

.controls {

  display: flex;
  flex-direction: row;
  flex: 1 0;
  margin: 40px 0 0;
  justify-content: flex-end;

  @media (max-width: $mobile) {
    display: none;
  }
}

.info {

  display: flex;

  @media (max-width: $mobile) {
    flex-direction: column-reverse;
    margin-top: 40px;
  }

  &__logo {
    display: inline-block;
    min-width: 80px;
  }

  &__address {
    margin-left: 56px;

    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
      margin-left: 0;
    }

    &-street {

      font-size: 12px;
      line-height: 1.46;

      @media (max-width: $mobile) {
        display: none;
      }
    }

    &-mail {
      margin-top: 5px;

      @media (max-width: $mobile) {
        margin: 0 0 44px;
      }
    }
  }

  &__social {
    display: flex;
    align-items: flex-start;

    @media (max-width: $mobile) {
      justify-content: center;
    }

    &-item {
      max-width: 24px;

      & + .info__social-item {
        margin-left: 18px;
      }
    }
  }
}

.images {

  display: flex;
  align-items: center;
  margin-left: 20px;

  @media (max-width: $mobile) {
    margin: 0;
  }

  &__item {
    max-width: 48px;

    & + .images__item {
      margin-left: 28px;
    }

    img {
      width: 100%;
    }
  }
}

.links {

  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  margin: 0 0 0 80px;
  font-size: 12px;
  line-height: 1.46;

  @media (max-width: $mobile) {
    max-width: 100%;
    width: 100%;
    margin: 0;
    font-size: 16px;
    line-height: 1.188;
  }

  &__item {

    display: flex;
    flex: 1 0;
    padding: 0 0 1em 0;
    min-width: 50%;
    box-sizing: border-box;
    justify-content: flex-end;

    @media (max-width: $mobile) {
      min-width: 100%;
      margin-right: 0;
      justify-content: flex-start;
      padding-bottom: 24px;
    }

    &-link {
      text-align: right;
      color: $black;
      text-decoration: none;
      max-width: 200px;

      @media (max-width: $mobile) {
        max-width: 100%;
      }
    }
  }
}

.mobile-hide {
  @media (max-width: $mobile) {
    display: none;
  }
}

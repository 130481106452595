
@import "~@packages/theme";


.wrapper {

  display: flex;
  flex: 1 0;
  flex-direction: column;
  background: $gray5;
  overflow: auto;
}

.header {

  display: block;
}

.content {

  position: relative;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  z-index: 20;
}

.footer {

  display: block;
  z-index: 10;
}


@import "~@packages/theme";


.wrapper {

  display: block;
  padding: 0 40px 66px;

  @media (max-width: $mobile) {
    padding: 0 0 66px;
    width: 100%;
  }

  &--one {

    padding: 0;
  }

  &--empty {

    width: 425px;
    height: 320px;
    padding: 0;
    box-shadow: 0 8px 16px -8px transparentize($black, .8);
    background: url("./empty.svg") no-repeat center $gray4;
    background-size: auto 36px;
    border-radius: 8px;
    overflow: hidden;
  }
}

.slider {

  position: relative;
  width: 425px;
  height: 320px;
  background-color: $gray4;
  box-shadow: 0 8px 16px -8px transparentize($black, .8);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $mobile) {
    width: 100%;
    max-width: 425px;
  }
}

.dots {

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 30px 0 0;

  & > li {

    display: inline-flex;
    flex-direction: column;
    flex: 1 0;
    border-radius: 4px;
    vertical-align: top;
    border: 1px solid transparent;
    cursor: pointer;
    transition: border-color 200ms;

    & + li {

      margin: 0 0 0 21px;

      @media (max-width: $mobile) {
        margin: 0 0 0 12px;
      }
    }
  }
}

:global {

  .slick-slide > div {

    height: 100%;
  }

  .slick-list {

    display: block;
    width: 100%;
    height: 320px;
    box-sizing: border-box;
  }

  .slick-track {

    display: flex;
    height: 320px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  li.slick-active {

    border-color: $black;
  }
}

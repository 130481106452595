
@font-face {
    font-family: 'Stem';
    src: local('Stem'),
    url('Stem/Stem.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Stem';
    src: local('Stem-Bold'),
    url('Stem/Stem-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}


@import "~@packages/theme";


.wrapper {

  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image {

  display: block;
  width: 100%;
}

@import "~@packages/theme";


.spinner {

  display: block;
  margin: auto auto;

  &__text {

    display: block;
    font-size: 14px;
    font-weight: 500;
    color: $dark-grey-blue;
    text-align: center;
  }

  &__holder {

    position: relative;
    display: block;
    height: 49px;
    width: 49px;
    margin: 0 auto 40px;

    &-animation {

      display: block;
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
      height: 49px;
      width: 49px;

      &:before,
      &:after {

        content: '';
        display: block;
        position: absolute;
        top: 0; left: 0;
        bottom: 0; right: 0;
        margin: auto;
        height: 44px;
        width: 44px;
        border-radius: 50%;
        opacity: 0;
        -webkit-animation: loader-6-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
        animation: loader-6-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
      }

      &:before {

        border: 3px solid $primary;
      }

      &:after {

        border: 3px solid $primary;
        -webkit-animation: loader-6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
        animation: loader-6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
      }
    }
  }
}

@-webkit-keyframes loader-6-1 {
  0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
  100% { -webkit-transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
}
@keyframes loader-6-1 {
  0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
  100% { transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
}

@-webkit-keyframes loader-6-2 {
  0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
  100% { -webkit-transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
}
@keyframes loader-6-2 {
  0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
  100% { transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
}


@import "~@packages/theme";


.wrapper {

  display: flex;
  flex-direction: column;
  flex: 1 0;
  box-sizing: border-box;
  overflow: auto;
  z-index: 90;
}

.spinner {

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  background: rgba($white, .9);
  z-index: 10;
}


@import "~@packages/theme";


.link {

  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.46;
  color: $bright-red;
  opacity: 1;
  cursor: pointer;
  transition: opacity 200ms;

  @media (max-width: $mobile) {
    font-size: 15px;
  }

  &:hover {
    opacity: .85;
  }
}

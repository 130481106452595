
@import "~@packages/theme";


.wrapper {

  display: flex;
  flex-direction: column;
  flex: 1 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image {

  display: inline-block;
  width: auto;
  max-width: 100%;
  height: 100%;
}

@import "~@packages/theme";


.wrapper {

  display: inline-flex;
  flex-direction: row;
  flex: 0 0;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.wrapper:hover .checkbox {

  border-color: $gray11;
}

.disabled {

  cursor: default;
}

.disabled .checkbox,
.disabled:hover .checkbox {

  background: transparent;
  border-color: $gray12;
  color: $pale-lilac;
  cursor: default;
}


.label {

  display: inline-flex;
  flex-direction: column;
  flex: 1 0;
  font-family: inherit;
  font-weight: 300;
  font-size: 14px;
  color: inherit;
  margin: 0 0 0 12px;
}

.checkbox {

  display: flex;
  flex-direction: column;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  border: 1px solid $gray10;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & + & {
    margin: 0 0 0 10px;
  }

  &__marker {

    display: none;
    width: 10px;
    height: 10px;
  }

  &--checked {
    background: $success;
  }

  &--checked &__marker {

    display: inline-flex;
    background: $success url("./icon.svg") center/100% no-repeat;

  }
}


@import "~@packages/theme";


.option {

  display: block;
  border-radius: 32px;
  cursor: pointer;
  transition: background-color 200ms;

  &:hover {
    background-color: $pale-grey;
  }

  &--active,
  &--active:hover {

    background-color: rgba($information, .3);
  }

  & + & {

    margin: 3px 0 0;
  }
}

.text {

  display: block;
  padding: 10px 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  color: $black;
}


@import "~@packages/theme";


.confirm {

  display: block;
  padding: 15px 50px 0;
  width: 460px;
  box-sizing: border-box;
}

.content {

  display: block;
  text-align: center;
}

.controls {

  display: block;
  margin: 40px 0 0;
  text-align: center
}

.reset {

  color: $white;

  &:hover {

    color: $pale-grey;
  }
}

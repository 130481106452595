
@import "~@packages/theme";


.wrapper {

  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto;
  z-index: 0;
}


@import "~@packages/theme";


.wrapper {

  display: flex;
  flex: 1 0;
  flex-direction: column;
}

.content {

  display: flex;
  flex: 1 0;
  flex-direction: column;
  background: $white;
  width: 100%;
}

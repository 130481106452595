
@import "~@packages/theme";

.button {

  display: inline-block;
  outline: 0 none;
  text-decoration: none;
  background-color: $bright-red;
  border: 1px solid $bright-red;
  color: $white;
  padding: 20px 56px;
  border-radius: 72px;
  font-family: inherit;
  cursor: pointer;
  transition: opacity .15s ease-in-out;

  &:hover {

    opacity: .9;
  }

  &.disabled {

    cursor: default;
    background-color: $gray;
    border-color: $gray;

    &:hover {
      opacity: 1;
    }
  }
}

.icon {

  display: inline-block;
  width: 27px;
  height: 27px;
  background: url("./cart.svg") center/100% no-repeat;
  margin: 0 15px 0 0;
  vertical-align: middle;
}

.caption {

  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  font-family: inherit;
  vertical-align: middle;
}

.mode--primary {

  background-color: $blue;
  border: 1px solid $blue;
  color: $white;
}

.mode--success {

  background-color: $green;
  border-color: $green;
  color: $white;
}

.size--small {

}

.size--large {

  font-size: 16px;
}

.size--giant {

}

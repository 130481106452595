
@import "~@packages/theme";

.button {

  display: inline-block;
  outline: 0 none;
  text-decoration: none;
  background-color: $bright-red;
  border: 1px solid $bright-red;
  color: $white;
  padding: 11px 30px;
  border-radius: 72px;
  font-size: 14px;
  font-family: inherit;
  font-weight: normal;
  cursor: pointer;
  transition: opacity .15s ease-in-out;

  &:hover {

    opacity: .9;
  }

  &.disabled {
    cursor: default;
    background-color: $gray;
    border-color: $gray;

    &:hover {
      opacity: 1;
    }
  }
}

.mode--primary {

  background-color: $blue;
  border: 1px solid $blue;
  color: $white;
}

.mode--success {

  background-color: $green;
  border-color: $green;
  color: $white;
}

.mode--info {

  background-color: $green3;
  border-color: $green3;
  color: $white;
}

.mode--warning {

  background-color: #F4BB2B;
  border-color: #F4BB2B;
  color: $black;
}

.size--small {

}

.size--large {

  height: 58px;
  font-size: 20px;
  line-height: 1.2;
}

.size--giant {

}

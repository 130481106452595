
@import "~@packages/theme";


.wrapper {

  display: block;
  padding: 15px 30px 10px;
  background: $white;
  border-bottom: 1px solid $pale-lilac;
  box-shadow: 0 3px 10px 0 rgba($pale-lilac,.5);
  z-index: 10;
}


@import "~@packages/theme";


.wrapper {

  display: block;
}

.link {

  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.46;
  text-decoration: none;
  color: $bright-red;
  opacity: 1;
  transition: opacity 200ms;

  @media (max-width: $mobile) {
    font-size: 20px;
    line-height: 1.45;
    text-decoration: underline;
  }

  &:hover {

    opacity: .85;

    @media (max-width: $mobile) {
      text-decoration: none;
    }
  }

  &.underline {

    text-decoration: underline;
    color: $black;
  }
}


@import "~@packages/theme";


.wrapper {

  display: block;
  padding: 38px 0;
  background-color: $white;

  @media (max-width: $mobile) {
    padding: 24px 0 24px;
  }
}

.container {

  display: flex;
  flex-direction: row;
  flex: 1 0;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 64px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: $mobile) {

    padding: 0 24px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.logotype {

  display: flex;
}

.controls {

  display: block;
  margin-left: 24px;

  @media (max-width: $mobile) {

    display: none;
  }
}

.user {

  margin-left: 24px;

  img {
    display: block;
  }
}

.contacts {

  display: flex;
  white-space: nowrap;
  margin: 0 32px;
  font: $f-bold 12px/1.46 'Stem';

  @media (max-width: $tablet) {
    margin: 32px 0;
  }

  &__delimiter {
    display: inline-block;
    margin: 0 8px;
  }

  &__link {

    &.black {
      color: $black;
    }
  }
}


@import "~@packages/theme";


.wrapper {

  position: relative;
  display: flex;
  flex: 1 0;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 60px 60px;
  box-sizing: border-box;

  @media(max-width: $mobile) {

    flex-direction: column;
    padding: 44px 24px 32px;
  }
}

.navigate {

  display: block;
  width: 350px;

  @media(max-width: $mobile) {

    width: 100%;
  }
}

.content {

  display: flex;
  flex-direction: column;
  flex: 1 0;
  margin: 0 0 0 50px;

  @media(max-width: $mobile) {

    margin: 48px 0 0;
  }
}

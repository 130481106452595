
@import "~@packages/theme";


.wrapper {

  display: block;
  width: 320px;

  @media (max-width: $mobile) {
    width: 100%;
  }
}

.header {

  display: block;
}

.content {

  display: block;
  margin: 34px 0 0;
}

.controls {

  display: block;
  margin: 30px 0 0;
}

.field {

  display: block;

  & + & {

    margin: 20px 0 0;
  }
}

.section {

  display: flex;
  flex-direction: column;
  flex: 1 0;

  & + & {

    margin: 28px 0 0;
  }
}

.controls {

  display: flex;
  flex-direction: column;
  flex: 1 0;
}

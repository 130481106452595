
@import "~@packages/theme";


.wrapper {

  display: block;
  width: 330px;
}

.header {

  display: block;
}

.content {

  display: block;
  margin: 34px 0 0;
}

.offer {

  display: flex;
  flex-direction: row;
  flex: 1 0;
  margin: 22px 0 0;
  align-items: flex-end;
}

.label {

  display: inline-block;
  margin: 0 0 0 12px;
  font-size: 12px;
  font-weight: 500;
}

.controls {

  display: block;
  margin: 30px 0 0;
}

.field {

  display: block;

  & + & {

    margin: 20px 0 0;
  }
}

.section {

  display: flex;
  flex-direction: column;
  flex: 1 0;

  & + & {

    margin: 16px 0 0;
  }
}

.sign-up-spinner {

  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 30px;
  text-align: center;

  &__icon {

    display: block;
  }

  &__message {

    display: block;
    text-align: center;
    font: 14px/1.46 'Stem';
    margin: 13px 0 0;
  }

  &__headline {
    margin: 28px 0 0;
    text-align: center;
  }
}
